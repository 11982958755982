<template>
  <footer class="app-footer text-center text-lg-start text-muted">
    <!-- Grid container -->
    <b-container class="p-4 info-panel">
      <!--Grid row-->
      <b-row>
        <!--Grid column-->
        <b-col lg="7" md="12" mb="4" mb-md="0" class="text-lg-left">
          <p><router-link to="/hello">Juhised</router-link></p>
          <p><router-link to="/legal/privacypolicy">Privaatsuspoliitika</router-link></p>
          <p><router-link to="/legal/termsandconditions">Kasutajatingimused</router-link></p>
        </b-col>
        <b-col lg="5" md="12" mb="4" mb-md="0" class="footer-links">
          <p>Kirjuta <a href="mailto:info@leluelu.ee">info@leluelu.ee</a></p>
          <p>Hoia silm peal:
            <a href="https://www.facebook.com/leluelu.swapper" target="_blank">
            <icon icon-name="fb" size="35px"></icon>
            </a>
            <a href="https://www.instagram.com/leluelu/" target="_blank">
            <icon icon-name="ig" size="35px"></icon>
            </a>
          </p>
          <p>Telli <a href="https://leluelu.us18.list-manage.com/subscribe?u=a271c681d0bc14df2480e54c0&amp;id=6bad72d529" target="_blank">Leluelu uudiskiri</a> oma meilile</p>
        </b-col>
        <!--Grid column-->
      </b-row>
      <!--Grid row-->
    </b-container>
    <!-- Grid container -->

    <!-- Copyright -->
    <div class="p-3 copyright">
      <b-row>
        <b-col class="text-left">
          <a href="https://www.facebook.com/leluelu.swapper" target="_blank">
            <icon icon-name="fb" size="35px"></icon>
          </a>
          <a href="https://www.instagram.com/leluelu/" target="_blank">
            <icon icon-name="ig" size="35px"></icon>
          </a>
        </b-col>
        <b-col class="text-center">
          <icon icon-name="logo" size="35px"></icon>
        </b-col>
        <b-col class="text-right">
          © 2024 Leluelu OÜ
        </b-col>
      </b-row>
    </div>
    <!-- Copyright -->
  </footer>
</template>

<script>

import { Component, Vue } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'

@Component({
  components: { Icon }
})
export default class AppFooter extends Vue {
}
</script>

<style scoped>

a {
  text-decoration: none;
  color: #6c757d;
}

a:hover {
  color: #FBA644;
}

.app-footer {
  box-shadow: 0 -25px 25px -25px rgba(168,168,168,0.55);
}

.copyright {
  background-color: #F2F2F2;
}

.info-panel {
  padding-top: 50px !important;
}

.footer-links {
  text-align: right;
}

svg {
  display:inline-block;
  position: relative;
  top: -3px;
}

svg.fb, svg.ig {
  filter: invert(48%) sepia(8%) saturate(473%) hue-rotate(166deg) brightness(91%) contrast(87%);
}

svg.fb:hover, svg.ig:hover {
  cursor: pointer;
  filter: invert(85%) sepia(12%) saturate(5295%) hue-rotate(325deg) brightness(99%) contrast(98%);
}

@media screen and (max-width: 991px) {
  .footer-links, .copyright {
    text-align: center;
  }
}
</style>
