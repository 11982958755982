
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import et from 'vee-validate/dist/locale/et'
import { ERROR_CODE } from '@/models/error-map'
import { errors } from 'jose'

const Auth = namespace('Auth')

@Component({
  computed: {
    errors () {
      return errors
    }
  }
})
export default class Login extends Vue {
  private user = { email: '', password: '' };
  private loading = false;
  private modalLoading = false;
  private message = '';
  private email = '';
  private modalMessage = '';

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Action
  private login!: (data: any) => Promise<any>;

  @Auth.Action
  private resetPassword!: (data: any) => Promise<any>;

  mounted (): void {
    this.$validator.localize('et', et)
    if (this.isLoggedIn) {
      this.$router.push('/profile')
    }

    this.scrollToView()
  }

  private scrollToView (): void {
    const pageContainer = document.getElementById('login-container')
    pageContainer.scrollIntoView({ behavior: 'smooth' })
  }

  handlePasswordReset (): void {
    this.modalLoading = true
    this.$validator.validate('reset-email').then((isValid) => {
      if (!isValid) {
        this.modalLoading = false
        return
      }
      if (this.email) {
        this.resetPassword(this.email).then(
          () => {
            this.modalLoading = false
            this.modalMessage = 'Email saadetud'
          },
          (error) => {
            this.modalLoading = false
            this.modalMessage = ERROR_CODE[error]
          }
        )
      }
    })
  }

  handleLogin (): void {
    this.loading = true
    this.$validator.validateAll().then((isValid) => {
      if (!isValid) {
        this.loading = false
        return
      }

      if (this.user.email && this.user.password) {
        this.login(this.user).then(() => {
          let redirect = '/home'
          if (this.$route.query.redirect) {
            // @ts-ignore
            redirect = this.$route.query.redirect
          }
          window.location.assign(redirect)
        },
        (error) => {
          this.loading = false
          this.message = ERROR_CODE[error]
        })
      }
    })
  }
}
