

import CategoryTag from '@/components/CategoryTag.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { AgeGroup, Category, Condition, Location } from '@/models/store'

const AppInfo = namespace('AppInfo')

@Component({ components: { CategoryTag } })
export default class SelectedFilters extends Vue {
  @AppInfo.Action
  private clearAllFilters: (param: any) => void;

  @AppInfo.Action
  private updateFilter: () => void;

  @AppInfo.State
  private filterAgeGroups: AgeGroup[];

  @AppInfo.State
  private filterCategories: Category[];

  @AppInfo.State
  private filterConditions: Condition[];

  @AppInfo.State
  private filterLocations: Location[];

  private resetForCategory (category: string): void {
    this.clearAllFilters(category)
    this.updateFilter()
  }
}
