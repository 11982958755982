// toyswapper error codes:
export const ERROR_CODE = {
  // generic error
  ERR_0: 'Midagi läks valesti. Palun proovige hiljem uuesti',

  // Auth errors:
  ERR_1: 'Ei õnnestunud autoriseerida',
  ERR_2: 'See email on juba võetud',
  ERR_3: 'Kasutajat ei leitud',
  ERR_4: 'Pildi lisamine ebaõnnestus',
  ERR_5: 'Kasutaja uuendamine ebaõnnestus',
  ERR_6: 'E-mail ja/või salasõna on vale',
  ERR_7: 'Ei õnnestunud uut kasutajat sisse logida',
  ERR_8: 'Salasõna peab koosnema suur- ja väiketähtedest, numbritest ja vähemalt ühest sümbolist',
  ERR_9: 'Te ei ole veel oma emaili verifitseerinud. Verifitseerimislingi saatsime teile registreerumisel sisestatud emailile.',
  ERR_10: 'Salasõna muutmine ei õnnestunud',
  ERR_11: 'Teie konto on deaktiveeritud',
  ERR_12: 'E-maili verifitseerimiseks mõeldud link on aegunud. Saatsime teile e-mailile uue lingi, et saaksite uuesti proovida.',
  ERR_13: 'Jagamiskood peab olema unikaalne! See kood on juba kellegi teise poolt kasutuses. Palun proovige mõnda muud koodi.',

  // SwapErrors
  ERR_101: 'Vahetus ei õnnestunud',
  ERR_102: 'Sul ei ole tellimuse esitamiseks piisavalt punkte',
  ERR_103: 'Endaga ei saa vahetada',
  ERR_104: 'Vahetus ei õnnestunud, kuna tellimuses esineb lelusid, mis on juba vahetuses.',

  // Toy errors
  ERR_201: 'Midagi läks valesti',
  ERR_202: 'Kasutaja ID on puudu',
  ERR_203: 'Nimi on puudu',
  ERR_204: 'Punktid on kohustuslik',
  ERR_205: 'Seisukord on kohustuslik',
  ERR_206: 'Seda lelu ei eksisteeri',

  // Profile errors
  ERR_301: 'Seda profiili ei eksisteeri'
}
