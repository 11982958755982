
import { Component, Vue } from 'vue-property-decorator'
import UserService from '@/services/UserService'
import { namespace } from 'vuex-class'
import { Toy } from '@/models/toy'
import ToyPhotoForm from '@/components/ToyPhotoForm.vue'
import PhotoUtilities from '@/utilities/PhotoUtilities'
import et from 'vee-validate/dist/locale/et.js'
import { ERROR_CODE } from '@/models/error-map'
import BigOrangeButton from '@/components/BigOrangeButton.vue'
import { TOY_STATUS_NAMES } from '@/models/id-maps'
import { errors } from 'jose'

const Auth = namespace('Auth')
const AppInfo = namespace('AppInfo')

@Component({
  computed: {
    errors () {
      return errors
    }
  },
  components: { ToyPhotoForm, BigOrangeButton }
})
export default class ToyForm extends Vue {
  @Auth.State('user')
  private currentUser!: any;

  @AppInfo.Getter
  private activeAgeGroups: [];

  @AppInfo.Getter
  private allLocations: [];

  @AppInfo.Getter
  private activeCategories: [];

  @AppInfo.Getter
  private activeConditions: [];

  private submitted = false;
  private disabled = true;
  private successful = false;
  private message = '';
  private changed = false;
  private showSpinner = false;

  private toy: Toy = {
    location: null,
    id: null,
    applicationUserId: null,
    toyInAgeGroups: [],
    toyInCategories: [],
    conditionId: null,
    description: null,
    name: null,
    swaps: [],
    toyPhotos: [],
    toyStatusId: null
  };

  private updatedToy: Toy = {
    id: null,
    description: null,
    location: null,
    price: null,
    conditionId: null,
    name: null,
    toyStatusId: null,
    toyInAgeGroups: [],
    toyInCategories: []
  };

  private productPhotos = [];

  private selectedProductPhotos = [];

  private photosToDelete = [];

  mounted (): void {
    this.$validator.localize('et', et)
    this.getToy()
  }

  private touched () {
    this.changed = true
    this.$validator.validateAll().then((isValid) => {
      if (isValid && this.toyModifiable()) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    })
  }

  private getToy (): void {
    if (this.$route.params.id != null) {
      UserService.getToy(this.$route.params.id).then(
        (response: any) => {
          this.updatedToy.conditionId = response.data.conditionId
          this.updatedToy.name = response.data.name
          this.updatedToy.description = response.data.description
          this.updatedToy.price = response.data.price
          this.updatedToy.location = response.data.location
          this.updatedToy.toyInAgeGroups = this.getToyInAgeGroupIds(response.data.toyInAgeGroups)
          this.updatedToy.toyInCategories = this.getToyInCategoryIds(response.data.toyInCategories)
          this.updatedToy.isActive = response.data.toyStatusId === 1
          this.setProductPhotos(response.data)
          this.toy = response.data
          this.successful = true
        },
        (error: any) => {
          console.error(ERROR_CODE[error.response.data.message])
          this.successful = false
        }
      )
    } else {
      this.updatedToy.isActive = true
    }
  }

  private getToyInAgeGroupIds (data: any) {
    const ids = []
    data.forEach((ag) => {
      ids.push(ag.ageGroupId)
    })
    return ids
  }

  private getToyInCategoryIds (data: any) {
    const ids = []
    data.forEach((cat) => {
      ids.push(cat.categoryId)
    })
    return ids
  }

  private setProductPhotos (toy: any): void {
    toy.toyPhotos.forEach(photo => {
      this.productPhotos.push({
        id: photo.id,
        reference: PhotoUtilities.getProductPhotoUrl(photo.photoReference)
      })
    })
  }

  private deleteSelected (id: number | string): void {
    this.productPhotos = this.productPhotos.filter(photo => photo.id !== id)
    this.selectedProductPhotos = this.selectedProductPhotos.filter(photo => photo.id !== id)
    this.photosToDelete.push(id)
    this.changed = true

    if (this.toy.id) {
      this.disabled = false
    }
  }

  private handleUpdateOrCreate (): void {
    this.message = ''
    this.submitted = true
    this.disabled = true

    if (this.changed || this.selectedProductPhotos.length > 0) {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          if (this.toy.id == null) {
            this.handleCreate()
          } else {
            this.handleUpdate()
          }
        }
      })
    }
  }

  private handleCreate (): void {
    this.message = ''
    this.showSpinner = true
    const payload = this.mapUpdate()
    UserService.createToy(payload).then(
      () => {
        this.successful = true
        this.$router.push('/home')
      },
      (error) => {
        this.message = ERROR_CODE[error.response.data.message]
        this.successful = false
      }
    )
  }

  private handleUpdate (): void {
    this.message = ''
    this.updatedToy.id = this.toy.id
    this.showSpinner = true

    const payload = this.mapUpdate()

    UserService.updateToy(payload).then(
      () => {
        this.successful = true
      },
      (error) => {
        this.message = ERROR_CODE[error.response.data.message]
        this.successful = false
      }
    ).then(() => {
      if (this.photosToDelete.length > 0) {
        this.photosToDelete.forEach(photoId => {
          if (!isNaN(photoId)) {
            UserService.deleteToyPhoto(this.toy.id, photoId).then(() => {
              this.$router.go(-1)
            })
          }
        })
      } else {
        this.$router.go(-1)
      }
    })
  }

  private mapUpdate (): Toy {
    const uploadedPhotos = this.selectedProductPhotos.map(p => p.photo)
    const result: Toy = {
      location: this.updatedToy.location == null ? this.toy.location : this.updatedToy.location,
      id: this.updatedToy.id == null ? this.toy.id : this.updatedToy.id,
      conditionId: this.updatedToy.conditionId == null ? this.toy.conditionId : this.updatedToy.conditionId,
      description: this.updatedToy.description == null ? this.toy.description : this.updatedToy.description,
      name: this.updatedToy.name == null ? this.toy.name : this.updatedToy.name,
      toyStatusId: this.getToyStatusId(),
      applicationUserId: this.currentUser.id,
      toyInAgeGroups: this.mapToyInAgeGroupIds(),
      toyInCategories: this.mapToyInCategoryIds(),
      toyPhotos: this.selectedProductPhotos && this.selectedProductPhotos.length > 0 ? uploadedPhotos : null,
      price: this.updatedToy.price == null ? this.toy.price : this.updatedToy.price
    }
    return result
  }

  private getToyStatusId (): number {
    if (this.toy.toyStatusId === 1 && !this.updatedToy.isActive) {
      return 2
    }
    if ((this.toy.toyStatusId === 2 && this.updatedToy.isActive) || this.toy.toyStatusId === null) {
      return 1
    }
    return this.toy.toyStatusId
  }

  private mapToyInAgeGroupIds (): any {
    return this.updatedToy.toyInAgeGroups == null ? this.toy.toyInAgeGroups.flatMap(ag => ag.ageGroupId) : this.updatedToy.toyInAgeGroups
  }

  private mapToyInCategoryIds (): any {
    return this.updatedToy.toyInCategories == null ? this.toy.toyInCategories.flatMap(cat => cat.categoryId) : this.updatedToy.toyInCategories
  }

  private async onFilesSelected (event: any) {
    const maxFiles = 6 - this.productPhotos.length
    for (let i = 0; i < event.target.files.length; i++) {
      if (i === maxFiles) return
      const image = event.target.files[i]

      const inputPreview = new Image()
      inputPreview.src = URL.createObjectURL(image)

      const uploadedPhotoId = (Math.random() + 1).toString(36).substring(7)
      this.productPhotos.push({
        id: uploadedPhotoId,
        reference: inputPreview.src
      })

      await PhotoUtilities.compressImage(inputPreview, 1000, 1000).then((result) => {
        this.selectedProductPhotos.push({
          id: uploadedPhotoId,
          photo: result
        })
      }).then(() => {
        this.$validator.validateAll().then((isValid) => {
          if (isValid) {
            this.disabled = false
          } else {
            this.disabled = true
          }
        })
      })
    }
  }

  toyModifiable (): boolean {
    return [1, 2].includes(this.toy.toyStatusId) || this.toy.id === null
  }

  get toyFormTitle () {
    return this.toy.id === null ? 'toote lisamine' : 'toote muutmine'
  }

  get toyStatusLabel () {
    return TOY_STATUS_NAMES[this.toy.toyStatusId]
  }
}

