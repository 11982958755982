
import { Component, Vue, Watch } from 'vue-property-decorator'
import UserService from '@/services/UserService'
import ChatService from '@/services/ChatService'
import { ApplicationUser, ChatPageRecipientInfo } from '@/models/application-user'
import { namespace } from 'vuex-class'
import { Message } from '@/models/chat'
import DateUtilities from '@/utilities/DateUtilities'
import UserAvatar from '@/components/UserAvatar.vue'
import ContactsNav from '@/components/pages/ContactsNav.vue'
import Icon from '@/components/Icon.vue'
import { Route } from 'vue-router'

const Auth = namespace('Auth')
const Chat = namespace('Chat')

@Component({
  components: { Icon, ContatcsNav: ContactsNav, UserAvatar }
})
export default class ChatPage extends Vue {
  @Auth.State('user')
  private currentUser!: ApplicationUser;

  @Chat.State('groupedMessages')
  private allMessages;

  @Chat.Action
  private storeNewMessage: (message: Message) => void;

  @Chat.Action
  private clearMessages: () => void;

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange (newVal: Route) {
    ChatService.closeConnection()
  }

  private correspondence = [];
  private toUserId = 0
  private message = {
    title: undefined,
    content: undefined
  }

  private recipientInfo: ChatPageRecipientInfo = {
    email: undefined,
    firstName: undefined,
    lastName: undefined
  }

  private emailNotificationsEnabled = true;

  mounted (): void {
    this.correspondence = []
    this.clearMessages()
    UserService.getChat(this.toUserId, this.currentUser.id).then((result) => {
      this.emailNotificationsEnabled = result.data.emailNotificationsEnabled
    })
  }

  created (): void {
    if (this.isChatSelected()) {
      this.fetchUser()
      this.getCorrespondence()
    }
  }

  private fetchUser (): void {
    const userId = this.$route.params.id
    if (userId != null) {
      UserService.getUser(userId).then(
        (response) => {
          this.recipientInfo = response.data
        },
        (error) => {
          this.recipientInfo =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        })
    }
  }

  private isChatSelected () {
    return this.$route.params.id !== null && this.$route.params.id !== undefined
  }

  private getCorrespondence () {
    this.toUserId = Number(this.$route.params.id)
    this.correspondence = []
    this.clearMessages()
    UserService.getCorrespondence(this.currentUser.id, this.$route.params.id)
      .then((response) => {
        const data = response.data
        for (const index in data) {
          const message = data[index]
          this.storeNewMessage(message)
        }
      }).then(() => {
        ChatService.startConnection().then(ChatService.connectToChat)
        this.setupCallbacks()
        this.updateCorrespondence()
      }).then(() => {
        UserService.markMessagesAsOpened(this.currentUser.id, this.toUserId)
      }).then(() => {
        this.scrollToBottom()
      })
  }

  private setupCallbacks (): void {
    ChatService.addCallbackToConnection('MessageReceived', (message) => {
      this.handleCallback(message).then(() => {
        this.scrollToBottom()
      })
    })
  }

  private async handleCallback (message) {
    const parsedMessage = JSON.parse(message)
    this.storeNewMessage(parsedMessage)
    await UserService.markMessagesAsOpened(parsedMessage.fromApplicationUserId, parsedMessage.toApplicationUserId)
    this.updateCorrespondence()
  }

  private updateCorrespondence (): void {
    this.correspondence = []
    const messagesToCurrentUser: any = this.allMessages.find(messageGroup => this.filterCorrespondence(messageGroup, this.currentUser.id, this.toUserId))
    const messagesFromCurrentUser: any = this.allMessages.find(messageGroup => this.filterCorrespondence(messageGroup, this.toUserId, this.currentUser.id))
    let combinedMessages = []
    if (messagesToCurrentUser !== undefined && messagesFromCurrentUser !== undefined) {
      combinedMessages = messagesToCurrentUser.messages.concat(messagesFromCurrentUser.messages)
    } else if (messagesFromCurrentUser !== undefined && messagesToCurrentUser === undefined) {
      combinedMessages = messagesFromCurrentUser.messages
    } else if (messagesFromCurrentUser === undefined && messagesToCurrentUser !== undefined) {
      combinedMessages = messagesToCurrentUser.messages
    }
    this.correspondence = combinedMessages.sort((message1, message2) => DateUtilities.compareDates(message1.createdAt, message2.createdAt))
  }

  private filterCorrespondence (messageGroup, fromUserId, toUserId) {
    return (messageGroup.fromUserId === fromUserId && messageGroup.toUserId === toUserId)
  }

  private getDateTime (message: Message): string {
    return DateUtilities.getDateTimeStringFromDate(new Date(message.createdAt))
  }

  private async sendMessage (event) {
    event.preventDefault()
    const message: Message = {
      title: this.message.title,
      fromApplicationUserId: this.currentUser.id,
      toApplicationUserId: this.toUserId,
      fromApplicationUserFirstName: this.currentUser.firstName,
      toApplicationUserFirstName: this.currentUser.firstName,
      fromApplicationUserProfilePictureReference: this.currentUser.profilePictureReference,
      content: this.message.content,
      messageStatus: 'new'
    }

    this.message = {
      title: undefined,
      content: undefined
    }

    if (message.content !== null && message.content !== undefined) {
      await ChatService.sendNewMessage(message).then(() => {
        this.storeNewMessage(message)
        this.updateCorrespondence()
      }).then(() => {
        this.scrollToBottom()
      })
    }
  }

  private scrollToBottom (): void {
    const messageContainer = document.getElementById('messages-container')
    messageContainer.scrollTop = messageContainer.scrollHeight + messageContainer.scrollHeight
  }

  private toggleEmailNotifications () {
    UserService.toggleEmailNotificationsForChat(this.toUserId, this.currentUser.id, this.emailNotificationsEnabled)
  }
}
