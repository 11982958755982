class DateUtilities {
  private locales = 'ru-RU'

  public getDateTimeStringFromDate (date: Date): string {
    return date.toLocaleString(this.locales)
  }

  public getDateTimeStringFromString (dateString: string) {
    const date = new Date(dateString)
    return date.toLocaleString(this.locales)
  }

  public getDateStringFromString (dateString: string) {
    const date = new Date(dateString)
    return date.toLocaleDateString(this.locales)
  }

  public getTimeStringFromString (dateString: string) {
    const date = new Date(dateString)
    return date.toLocaleTimeString(this.locales)
  }

  public getDateFromString (dateString: string): Date {
    return new Date(dateString)
  }

  public compareDates (date1, date2) {
    if (this.getDateFromString(date1) > this.getDateFromString(date2)) {
      return 1
    } else if (this.getDateFromString(date1) < this.getDateFromString(date2)) {
      return -1
    } else {
      return 0
    }
  }
}

export default new DateUtilities()
