import axios from 'axios'
import ServiceUtility from '@/utilities/EnvUtilities'
import authHeader from '@/services/auth-header'
import { ToySearchRequest } from '@/models/data'

const API_URL = ServiceUtility.getApiBaseUrl()

class BasicService {
  searchToys (request: ToySearchRequest) {
    return axios.create({
      headers: {
        'Content-Type': 'application/json'
      }
    }).post(API_URL + 'Toys/Search', request, { headers: authHeader() })
  }

  getAllToys () {
    return axios.get(API_URL + 'Toys/GetAll')
  }

  getPublicInfo () {
    return axios.get(API_URL + 'PublicInfo/GetAll')
  }
}

export default new BasicService()
