
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import CategorySection from '@/components/pages/adminPages/CategorySection.vue'
const AppInfo = namespace('AppInfo')

@Component({
  components: { CategorySection }
})
export default class ParameterManager extends Vue {
  @AppInfo.Getter
  private allAgeGroups: [];

  @AppInfo.Getter
  private allCategories: [];

  @AppInfo.Getter
  private allConditions: [];
}

