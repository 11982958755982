class EnvUtilities {
  getEnv (): any|string {
    if (process.env.NODE_ENV === 'development') {
      return 'localhost:8080'
    }
    return 'https://www.leluelu.ee'
  }

  getApiBaseUrl () {
    if (process.env.NODE_ENV === 'development') {
      return process.env.VUE_APP_API_URL
    }
    return 'https://api.leluelu.ee/api/v1/'
  }

  getMessagingEndpoint () {
    if (process.env.NODE_ENV === 'development') {
      return process.env.VUE_APP_MESSAGING_ENDPOINT
    } return 'https://api.leluelu.ee/messaging'
  }

  getProfilePhotoBucket () {
    if (process.env.NODE_ENV === 'development') {
      return process.env.VUE_APP_PROFILE_PHOTO_BUCKET
    } return 'toyswapper-profile-photos'
  }

  getProductPhotoBucket () {
    if (process.env.NODE_ENV === 'development') {
      return process.env.VUE_APP_PRODUCT_PHOTO_BUCKET
    } return 'toyswapper-product-photos'
  }
}

export default new EnvUtilities()
