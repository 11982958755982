import axios from 'axios'
import authHeader from './auth-header'
import { Toy } from '@/models/toy'
import ServiceUtility from '@/utilities/EnvUtilities'

const API_URL = ServiceUtility.getApiBaseUrl()

class UserService {
  getUser (userId: string) {
    return axios.get(API_URL + 'Account/GetUser/' + userId, { headers: authHeader() })
  }

  getToy (toyId: string) {
    return axios.get(API_URL + 'Toys/Get/' + toyId, { headers: authHeader() })
  }

  async inactivateToy (toyId: string) {
    await axios.get(API_URL + 'Toys/Inactivate/' + toyId, { headers: authHeader() })
      .then(
        response => {
          return response.data
        }
      )
  }

  async activateToy (toyId: string) {
    await axios.get(API_URL + 'Toys/Activate/' + toyId, { headers: authHeader() })
      .then(
        response => {
          return response.data
        }
      )
  }

  async deleteToyPhoto (toyId: number, toyPhotoId: number) {
    return axios.post(API_URL + 'ToyPhotos/DeleteToyPhoto',
      { toyId: toyId, toyPhotoId: toyPhotoId },
      { headers: authHeader() })
  }

  getCorrespondence (fromUserId, toUserId) {
    return axios.create({
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).post(API_URL + 'Messages/GetMessages',
      { fromUserId: fromUserId, toUserId: toUserId },
      { headers: authHeader() })
  }

  getAllChatsNew (userId) {
    return axios.get(API_URL + 'Chats/GetAllChats/' + userId, { headers: authHeader() })
  }

  getNewMessages (userId) {
    return axios.get(API_URL + 'Chats/GetChatsWithNewMessages/' + userId, { headers: authHeader() })
  }

  getNewNotifications (userId) {
    return axios.get(API_URL + 'Notifications/GetNewNotifications/' + userId, { headers: authHeader() })
  }

  markNotificationAsOpened (userId, notificationId) {
    return axios.post(API_URL + 'Notifications/MarkAsOpened',
      { userId: userId, notificationId: notificationId },
      { headers: authHeader() })
  }

  markMessagesAsOpened (fromUserId: number, toUserId: number) {
    return axios.post(API_URL + 'Chats/MarkAsOpened',
      { fromUserId: fromUserId, toUserId: toUserId },
      { headers: authHeader() })
  }

  getChat (otherUserId: number, currentUserId: number) {
    return axios.post(API_URL + 'Chats/GetChat',
      { toUserId: currentUserId, fromUserId: otherUserId },
      { headers: authHeader() })
  }

  toggleEmailNotificationsForChat (toUserId: number, fromUserId: number, emailNotificationsEnabled: boolean) {
    return axios.post(API_URL + 'Chats/ToggleEmails',
      { fromUserId: fromUserId, toUserId: toUserId, emailsDisabled: emailNotificationsEnabled },
      { headers: authHeader() })
  }

  createToy (toy: Toy) {
    if (toy.toyPhotos !== null && toy.toyPhotos.length > 6) {
      return
    }
    const fd = this.composeToyForm(toy)

    return axios
      .create({
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .post(API_URL + 'Toys/CreateToy', fd, { headers: authHeader() })
      .then(
        response => {
          return response.data
        }
      )
  }

  updateToy (toy) {
    const fd = this.composeToyForm(toy)
    return axios
      .create({
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .put(API_URL + 'Toys/UpdateToy/' + toy.id, fd, { headers: authHeader() })
      .then(
        response => {
          return response.data
        }
      )
  }

  private composeToyForm (toy) {
    const fd = new FormData()
    // have to add some sort of limit as to how many pictures are allowed per toy
    if (toy.toyPhotos !== null) {
      for (let i = 0; i < toy.toyPhotos.length; i++) {
        const photo = toy.toyPhotos[i]
        const uuidV4 = this.generateUuidV4()
        fd.append('formFile', photo, `${uuidV4}`)
      }
    }
    if (toy.applicationUserId != null) {
      fd.append('ownerId', toy.applicationUserId.toString())
    }
    if (toy.id !== null) {
      fd.append('id', toy.id.toString())
    }
    if (toy.name !== null) {
      fd.append('name', toy.name)
    }
    if (toy.description !== null) {
      fd.append('description', toy.description)
    }
    if (toy.location !== null) {
      fd.append('location', toy.location)
    }
    fd.append('price', toy.price)
    if (toy.conditionId !== null) {
      fd.append('conditionId', toy.conditionId.toString())
    }
    if (toy.toyInAgeGroups !== null && toy.toyInAgeGroups.length > 0) {
      fd.append('ageGroupIds', toy.toyInAgeGroups.join(','))
    }
    if (toy.toyInCategories !== null && toy.toyInCategories.length > 0) {
      fd.append('categoryIds', toy.toyInCategories.join(','))
    }
    if (toy.toyStatusId !== null && [1, 2].includes(toy.toyStatusId)) {
      fd.append('toyStatusId', toy.toyStatusId)
    }
    return fd
  }

  generateUuidV4 (): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0
      const v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  }
}

export default new UserService()
