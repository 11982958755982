import { render, staticRenderFns } from "./ChatPage.vue?vue&type=template&id=14735e42&scoped=true"
import script from "./ChatPage.vue?vue&type=script&lang=ts"
export * from "./ChatPage.vue?vue&type=script&lang=ts"
import style0 from "./ChatPage.vue?vue&type=style&index=0&id=14735e42&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14735e42",
  null
  
)

export default component.exports