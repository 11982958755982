import axios from 'axios'
import authHeader from './auth-header'
import ServiceUtility from '@/utilities/EnvUtilities'
import CartService from '@/services/CartService'
import * as jose from 'jose'
import DateUtilities from '@/utilities/DateUtilities'

const API_URL = ServiceUtility.getApiBaseUrl() + 'Account'

class AuthService {
  login (email: string, password: string) {
    return axios
      .create({
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .post(API_URL + '/Login', {
        email,
        password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
      })
  }

  logout () {
    localStorage.removeItem('user')
    CartService.emptyCart()
  }

  emailVerification (token: string, email: string) {
    return axios.get(`${API_URL}/Verify?token=${token}&email=${email}`)
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
      })
  }

  passwordReset (email: string) {
    return axios
      .create({
        headers: {
          'Content-Type': 'application/json'
        }
      }).post(`${API_URL}/ForgotPassword`, {
        email: email
      })
  }

  confirmPasswordReset (token: string, email: string, newPassword: string) {
    return axios
      .create({
        headers: {
          'Content-Type': 'application/json'
        }
      }).post(`${API_URL}/VerifyPasswordReset`, {
        token: token,
        email: email,
        password: newPassword
      })
  }

  isValidSession (): boolean {
    const storedUser = localStorage.getItem('user')
    const user = JSON.parse(storedUser || '{}')
    if (user !== null && user !== undefined && user.token !== null && user.token !== undefined) {
      const JWT = jose.decodeJwt(user.token)
      const timestamp = new Date(JWT.exp * 1000)
      const difference = DateUtilities.compareDates(timestamp, new Date())
      if (difference < 0) {
        localStorage.removeItem('user')
        CartService.emptyCart()
        window.location.reload()
        return false
      }
    }
    return true
  }

  register (email: string, firstName: string, lastName: string, password: string, referralCode: string) {
    return axios.post(API_URL + '/Register', {
      email,
      firstName,
      lastName,
      password,
      referralCode
    })
  }

  agreeToTerms () {
    return axios.post(API_URL + '/AgreeToTerms', null, { headers: authHeader() }).then(
      response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }
        return response.data
      })
  }

  updateProfile (data) {
    const fd = new FormData()
    if (data.profilePicture !== null) {
      fd.append('formFile', data.profilePicture, data.profilePicture.name)
    }

    if (data.id != null) {
      fd.append('id', data.id)
    }

    if (data.email != null) {
      fd.append('email', data.email)
    }

    if (data.facebook === null || data.facebook.length < 1) {
      fd.append('facebook', '')
    } else {
      fd.append('facebook', 'https://www.facebook.com/' + data.facebook)
    }

    if (data.firstName != null) {
      fd.append('firstName', data.firstName)
    } else {
      fd.append('firstName', '')
    }

    if (data.lastName != null) {
      fd.append('lastName', data.lastName)
    } else {
      fd.append('lastName', '')
    }

    if (data.password != null) {
      fd.append('password', data.password)
    }

    if (data.emailNotificationsEnabled != null) {
      fd.append('emailNotificationsEnabled', data.emailNotificationsEnabled)
    }

    if (data.referralCode == null) {
      fd.append('referralCode', '')
    } else {
      fd.append('referralCode', data.referralCode)
    }

    return axios
      .create({
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .post(API_URL + '/Update', fd, { headers: authHeader() })
      .then(
        response => {
          if (response.data.token) {
            localStorage.setItem('user', JSON.stringify(response.data))
          }
          return response.data
        })
  }
}

export default new AuthService()
