export default function authHeader () {
  const storedUser = localStorage.getItem('user')
  const user = JSON.parse(storedUser || '{}')

  if (user && user.token) {
    return {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.token
    }
  } else {
    return {}
  }
}
