import axios from 'axios'
import authHeader from '@/services/auth-header'
import ServiceUtility from '@/utilities/EnvUtilities'
import { Notification } from '@/models/notification'

const API_URL = ServiceUtility.getApiBaseUrl()

class AdminService {
  async createItem (itemType: string, item: any) {
    const type = items[itemType]
    await axios.post(API_URL + type + '/Create', item, { headers: authHeader() })
  }

  async deleteItem (itemType: string, itemId: number) {
    const itemPath = getItemPath(itemType, itemId, 'Delete')
    await axios.delete(API_URL + itemPath, { headers: authHeader() })
  }

  async updateItem (type: string, item: any) {
    const itemPath = getItemPath(type, item.id, 'Update')
    await axios.put(API_URL + itemPath, item, { headers: authHeader() })
  }

  fetchPortalSettings () {
    return axios.get(API_URL + 'PortalSettings/GetAll')
  }

  async updateSetting (item: any) {
    await axios.put(API_URL + 'PortalSettings/Update/' + item.id, item, { headers: authHeader() })
  }

  sendNotification (notification: Notification) {
    return axios.post(API_URL + 'Notifications/CreateNotification',
      notification,
      { headers: authHeader() })
  }

  fetchAllNotifications () {
    return axios.get(API_URL + 'Notifications/GetAllNotifications', { headers: authHeader() })
  }

  fetchAllUsers () {
    return axios.get(API_URL + 'Account/GetAll', { headers: authHeader() })
  }

  updateUser (data) {
    return axios.post(API_URL + 'Account/UpdateUser', data, { headers: authHeader() })
  }

  updateToyAdmin (toy) {
    const data = {
      toyId: toy.id,
      ApplicationUserId: toy.applicationUserId,
      ToyStatusId: toy.toyStatusId
    }
    return axios
      .create({
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .post(API_URL + 'Toys/UpdateToyAdmin', data, { headers: authHeader() })
      .then(
        response => {
          return response.data
        }
      )
  }
}

const items = {
  ageGroup: 'AgeGroups',
  category: 'Categories',
  condition: 'Conditions'
}

function getItemPath (itemType: string, itemId: number, action: string): string {
  const type = items[itemType]
  return type + '/' + action + '/' + itemId
}

export default new AdminService()
