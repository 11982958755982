

import { Component, Prop, Vue } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'
import Sorter from '@/components/Sorter.vue'
import { namespace } from 'vuex-class'
import { AgeGroup, Category, Condition, Location } from '@/models/store'

const AppInfo = namespace('AppInfo')

@Component({
  components: { Icon, Sorter }
})
export default class FilterMenu extends Vue {
  @AppInfo.Action private clearAllFilters: (param: any) => void;
  @AppInfo.Action private updateFilter: () => void;
  @AppInfo.Getter private allAgeGroups: AgeGroup[];
  @AppInfo.Getter private allCategories: Category[];
  @AppInfo.Getter private allConditions: Condition[];
  @AppInfo.Getter private allFilterLocations: Location[];
  @AppInfo.State private filterAgeGroups: AgeGroup[];
  @AppInfo.State private filterCategories: Category[];
  @AppInfo.State private filterConditions: Condition[];
  @AppInfo.State private filterLocations: Location[];
  @Prop() secondaryMenuOpen: boolean;

  private categories = false;
  private ageGroups = false;
  private conditions = false;
  private locations = false;

  private openTypeOptions (type: string): void {
    switch (type) {
      case 'categories':
        this.categories = true
        this.ageGroups = false
        this.conditions = false
        this.locations = false
        break
      case 'ageGroups':
        this.categories = false
        this.ageGroups = true
        this.conditions = false
        this.locations = false
        break
      case 'conditions':
        this.categories = false
        this.ageGroups = false
        this.conditions = true
        this.locations = false
        break
      case 'locations':
        this.categories = false
        this.ageGroups = false
        this.conditions = false
        this.locations = true
        break
    }

    this.openSecondaryMenu(true)
  }

  closeSecondaryMenu (): void {
    this.openSecondaryMenu(false)
  }

  openSecondaryMenu (open: boolean): void {
    this.$emit('openSecondaryMenu', open)
  }

  private clearFilters (): void {
    this.clearAllFilters('ageGroup')
    this.clearAllFilters('category')
    this.clearAllFilters('condition')
    this.clearAllFilters('location')
    this.updateFilter()
  }
}
