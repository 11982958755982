import Vue from 'vue'
import Vuex from 'vuex'

import Auth from './modules/auth.module'
import AppInfo from './modules/app-info.module'
import Chat from './modules/chat.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Auth, AppInfo, Chat
  }
})
