import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import BasicService from '@/services/BasicService'
import { Toy } from '@/models/toy'
import { AgeGroup, Category, Condition, SwapStatus, ToyStatus, UserStatus, Location } from '@/models/store'
import { ToySearchRequest } from '@/models/data'
import DateUtilities from '@/utilities/DateUtilities'

@Module({ namespaced: true })
class AppInfo extends VuexModule {
  private categories: Category[] = [];
  private ageGroups: AgeGroup[] = [];
  private conditions: Condition[] = [];
  private toyStatuses: ToyStatus[] = [];
  private swapStatuses: SwapStatus[] = [];
  private userStatuses: UserStatus[] = [];
  private filterCategories: Category[] = [];
  private filterAgeGroups: AgeGroup[] = [];
  private filterConditions: Condition[] = [];
  private filterLocations: Location[] = [];
  private filterSearchTerm: string;
  private allToys: Toy[] = [];
  private activeToys: Toy[] = [];
  private toysReady = false;
  private locations: any[] = ['Abja-Paluoja', 'Antsla', 'Elva', 'Haapsalu', 'Jõgeva', 'Jõhvi',
    'Kallaste', 'Kärdla', 'Karksi-Nuia', 'Kehra', 'Keila', 'Kilingi-Nõmme', 'Kiviõli', 'Kohtla-Järve', 'Kunda', 'Kuressaare',
    'Lihula', 'Loksa', 'Maardu', 'Mõisaküla', 'Mustvee', 'Narva-Jõesuu', 'Narva', 'Otepää', 'Paide', 'Paldiski', 'Pärnu', 'Põltsamaa', 'Põlva', 'Püssi',
    'Rakvere', 'Räpina', 'Rapla', 'Saue', 'Sillamäe', 'Sindi', 'Suure-Jaani', 'Tallinn', 'Tamsalu', 'Tapa', 'Tartu', 'Tõrva', 'Türi',
    'Valga', 'Viljandi', 'Võhma', 'Võru'];

  private currentPageMainSearch = 1;
  private currentPageProfileToysActive = 1;
  private currentPageProfileToysInactive = 1;

  @Action
  public fetchData (): void {
    BasicService.getPublicInfo().then(
      (response: any) => {
        this.context.commit('storeInfo', response.data)
      }
    )
  }

  @Action
  public fetchToys (request: ToySearchRequest): void {
    BasicService.searchToys(request).then(
      (response) => {
        this.context.commit('storeToys', response.data)
      }
    )
  }

  @Action
  public toggleFilterParameter (param: any): void {
    this.context.commit('toggleFilterParam', param)
  }

  @Action
  public updateFilter (): void {
    this.context.commit('setToysNotReady')
    const request: ToySearchRequest = {
      pageNumber: 1,
      pageSize: 24,
      ageGroupIds: this.filterAgeGroups.map(ag => ag.value),
      categoryIds: this.filterCategories.map(cat => cat.value),
      conditionIds: this.filterConditions.map(con => con.value),
      locations: this.filterLocations.map(loc => loc.text),
      toyStatusIds: [1],
      searchTerm: this.filterSearchTerm
    }

    BasicService.searchToys(request).then(
      (response) => {
        this.context.commit('storeToys', response.data)
      }
    )
  }

  @Action
  public updateSearchTerm (term: string) {
    this.context.commit('updateSearchKeyword', term)
    this.context.commit('setToysNotReady')
    const request: ToySearchRequest = {
      pageNumber: 1,
      pageSize: 24,
      ageGroupIds: this.filterAgeGroups.map(ag => ag.value),
      categoryIds: this.filterCategories.map(cat => cat.value),
      conditionIds: this.filterConditions.map(con => con.value),
      locations: this.filterLocations.map(loc => loc.text),
      toyStatusIds: [1],
      searchTerm: term
    }

    BasicService.searchToys(request).then(
      (response) => {
        this.context.commit('storeToys', response.data)
      }
    )
  }

  @Action
  public clearAllFilters (param: any): void {
    this.context.commit('inactivateAllFilterParams', param)
  }

  @Action
  public rememberPage (data: {type: string, pageNr: number}): void {
    this.context.commit('rememberSearchPage', data)
  }

  @Mutation
  updateSearchKeyword (term: string): void {
    this.filterSearchTerm = term
  }

  @Mutation
  setToysNotReady () {
    this.toysReady = false
  }

  @Mutation
  public toggleFilterParam (param: any): void {
    if (param.type === 'category') {
      if (this.filterCategories.includes(param)) {
        this.filterCategories = this.filterCategories.filter(cat => cat.text !== param.text)
      } else {
        this.filterCategories.push(param)
      }
    } else if (param.type === 'ageGroup') {
      if (this.filterAgeGroups.includes(param)) {
        this.filterAgeGroups = this.filterAgeGroups.filter(ag => ag.text !== param.text)
      } else {
        this.filterAgeGroups.push(param)
      }
    } else if (param.type === 'condition') {
      if (this.filterConditions.includes(param)) {
        this.filterConditions = this.filterConditions.filter(con => con.text !== param.text)
      } else {
        this.filterConditions.push(param)
      }
    } else if (param.type === 'location') {
      if (this.filterLocations.includes(param)) {
        this.filterLocations = this.filterLocations.filter(location => location.text !== param.text)
      } else {
        this.filterLocations.push(param)
      }
    }
  }

  @Mutation
  public inactivateAllFilterParams (param: any): void {
    if (param === 'category') {
      this.filterCategories = []
    } else if (param === 'ageGroup') {
      this.filterAgeGroups = []
    } else if (param === 'condition') {
      this.filterConditions = []
    } else if (param === 'location') {
      this.filterLocations = []
    }
  }

  @Mutation
  public adjustFilter (): void {
    if (this.filterAgeGroups.length === 0 && this.filterCategories.length === 0 && this.filterConditions.length === 0 && this.filterLocations.length === 0) {
      this.activeToys = []
      this.allToys.forEach(toy => {
        if (toy.toyStatusId === 1) {
          this.activeToys.push(toy)
        }
      })
    } else {
      this.activeToys = this.allToys.filter(toy => {
        let toyInAgeGroups
        if (this.filterAgeGroups.length > 0) {
          toyInAgeGroups = toy.toyInAgeGroups.find(ageGroup => {
            return this.filterAgeGroups.find(ag => ag.value === ageGroup.ageGroupId)
          })
        } else {
          toyInAgeGroups = true
        }
        let toyInCategories
        if (this.filterCategories.length > 0) {
          toyInCategories = toy.toyInCategories.find(category => {
            return this.filterCategories.find(cat => cat.value === category.categoryId)
          })
        } else {
          toyInCategories = true
        }
        let condition
        if (this.filterConditions.length > 0) {
          condition = this.filterConditions.find(condition => condition.value === toy.conditionId)
        } else {
          condition = true
        }
        let location
        if (this.filterLocations.length > 0) {
          location = this.filterLocations.find(location => location.text === toy.location)
        } else {
          location = true
        }
        return toyInCategories && toyInAgeGroups && condition && location && toy.toyStatusId === 1
      })
    }
  }

  @Mutation
  public storeInfo (data): void {
    data.categories.forEach(x => { this.categories.push({ type: 'category', value: x.id, text: x.name, description: x.description, active: x.active, updatedAt: x.updatedAt }) })
    data.ageGroups.forEach(x => { this.ageGroups.push({ type: 'ageGroup', value: x.id, text: x.name, description: x.description, active: x.active, updatedAt: x.updatedAt }) })
    data.conditions.forEach(x => { this.conditions.push({ type: 'condition', value: x.id, text: x.name, description: x.description, active: x.active, updatedAt: x.updatedAt }) })
    data.toyStatuses.forEach(x => { this.toyStatuses.push({ type: 'toyStatus', value: x.id, text: x.name, description: x.description }) })
    data.swapStatuses.forEach(x => { this.swapStatuses.push({ type: 'swapStatus', value: x.id, text: x.name, description: x.description }) })
    data.userStatuses.forEach(x => { this.userStatuses.push({ type: 'userStatus', value: x.id, text: x.name, description: x.description }) })
  }

  @Mutation
  public storeToys (data): void {
    this.allToys = []
    this.activeToys = []
    data.forEach(toy => {
      this.allToys.push(toy)
      if (toy.toyStatusId === 1) {
        this.activeToys.push(toy)
      }
    })
    this.allToys.sort((toy1, toy2) => {
      return DateUtilities.compareDates(toy2.createdAt, toy1.createdAt)
    })
    this.toysReady = true
  }

  @Mutation
  public rememberSearchPage (data: { type: string, pageNr: number }) {
    if (data.type === 'main') {
      this.currentPageMainSearch = data.pageNr
    } else if (data.type === 'profile_active') {
      this.currentPageProfileToysActive = data.pageNr
    } else if (data.type === 'profile_inactive') {
      this.currentPageProfileToysInactive = data.pageNr
    }
  }

  get allCategories () {
    return this.categories
  }

  get activeCategories () {
    return this.categories.filter(cat => { return cat.active === true })
  }

  get allAgeGroups () {
    return this.ageGroups
  }

  get activeAgeGroups () {
    return this.ageGroups.filter(ag => { return ag.active === true })
  }

  get allConditions () {
    return this.conditions
  }

  get activeConditions () {
    return this.conditions.filter(con => { return con.active === true })
  }

  get allToyStatuses () {
    return this.toyStatuses
  }

  get allSwapStatuses () {
    return this.swapStatuses
  }

  get allUserStatuses () {
    return this.userStatuses
  }

  get allActiveToys () {
    return this.activeToys
  }

  get toys () {
    return this.allToys
  }

  get allLocations () {
    return this.locations
  }

  get isToysReady () {
    return this.toysReady
  }

  get allFilterLocations () {
    return [
      { id: 0, text: 'Abja-Paluoja', type: 'location' },
      { id: 1, text: 'Antsla', type: 'location' },
      { id: 2, text: 'Elva', type: 'location' },
      { id: 3, text: 'Haapsalu', type: 'location' },
      { id: 4, text: 'Jõgeva', type: 'location' },
      { id: 5, text: 'Jõhvi', type: 'location' },
      { id: 6, text: 'Kallaste', type: 'location' },
      { id: 7, text: 'Kärdla', type: 'location' },
      { id: 8, text: 'Karksi-Nuia', type: 'location' },
      { id: 9, text: 'Kehra', type: 'location' },
      { id: 10, text: 'Keila', type: 'location' },
      { id: 11, text: 'Kilingi-Nõmme', type: 'location' },
      { id: 12, text: 'Kiviõli', type: 'location' },
      { id: 13, text: 'Kohtla-Järve', type: 'location' },
      { id: 14, text: 'Kunda', type: 'location' },
      { id: 15, text: 'Kuressaare', type: 'location' },
      { id: 16, text: 'Lihula', type: 'location' },
      { id: 17, text: 'Loksa', type: 'location' },
      { id: 18, text: 'Maardu', type: 'location' },
      { id: 19, text: 'Mõisaküla', type: 'location' },
      { id: 20, text: 'Mustvee', type: 'location' },
      { id: 21, text: 'Narva-Jõesuu', type: 'location' },
      { id: 22, text: 'Narva', type: 'location' },
      { id: 23, text: 'Otepää', type: 'location' },
      { id: 24, text: 'Paide', type: 'location' },
      { id: 25, text: 'Paldiski', type: 'location' },
      { id: 26, text: 'Pärnu', type: 'location' },
      { id: 27, text: 'Põltsamaa', type: 'location' },
      { id: 28, text: 'Põlva', type: 'location' },
      { id: 29, text: 'Püssi', type: 'location' },
      { id: 30, text: 'Rakvere', type: 'location' },
      { id: 31, text: 'Räpina', type: 'location' },
      { id: 32, text: 'Rapla', type: 'location' },
      { id: 33, text: 'Saue', type: 'location' },
      { id: 34, text: 'Sillamäe', type: 'location' },
      { id: 35, text: 'Sindi', type: 'location' },
      { id: 36, text: 'Suure-Jaani', type: 'location' },
      { id: 37, text: 'Tallinn', type: 'location' },
      { id: 38, text: 'Tamsalu', type: 'location' },
      { id: 39, text: 'Tapa', type: 'location' },
      { id: 40, text: 'Tartu', type: 'location' },
      { id: 41, text: 'Tõrva', type: 'location' },
      { id: 42, text: 'Türi', type: 'location' },
      { id: 43, text: 'Valga', type: 'location' },
      { id: 44, text: 'Viljandi', type: 'location' },
      { id: 45, text: 'Võhma', type: 'location' },
      { id: 46, text: 'Võru', type: 'location' }
    ]
  }

  get currentPageMain () {
    return this.currentPageMainSearch
  }

  get currentPageProfileActive () {
    return this.currentPageProfileToysActive
  }

  get currentPageProfileInactive () {
    return this.currentPageProfileToysInactive
  }
}

export default AppInfo
