import * as signalR from '@microsoft/signalr'
import { LogLevel } from '@microsoft/signalr'
import EnvUtilities from '@/utilities/EnvUtilities'

const SIGNALR_HUB_URL = EnvUtilities.getMessagingEndpoint()

function getUserFromSession () {
  const storedUser = localStorage.getItem('user')
  const user = JSON.parse(storedUser || '{}')
  return user
}

const connection = new signalR.HubConnectionBuilder()
  .withUrl(SIGNALR_HUB_URL, {
    accessTokenFactory: () => getUserFromSession() !== null ? getUserFromSession().token : null
  })
  .configureLogging(LogLevel.Information)
  .withAutomaticReconnect()
  .build()

class ChatService {
  async startConnection () {
    try {
      if (connection.state !== 'Connected') {
        await connection.start()
      }
    } catch (err) {
      console.error(err)
    }
  }

  async connectToChat () {
    const user = getUserFromSession()
    const payload = {
      username: user.email,
      userId: user.id
    }
    try {
      await connection.invoke('Connect', payload)
    } catch (err) {
      console.error(err)
    }
  }

  async sendNewMessage (payload) {
    try {
      if (connection.state !== 'Connected') {
        await connection.start()
      }
      await connection.invoke('SendMessage', payload)
    } catch (err) {
      console.error(err)
    }
  }

  public addCallbackToConnection (methodName, callbackMethod) {
    connection.on(methodName, callbackMethod)
  }

  async closeConnection () {
    if (connection.state === 'Connected') {
      connection.off('MessageReceived')
      await connection.stop()
    }
  }
}

export default new ChatService()
