

import ChatBox from '@/components/messaging/ChatBox.vue'
import Icon from '@/components/Icon.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ChatService from '@/services/ChatService'
import { namespace } from 'vuex-class'
import { ApplicationUser } from '@/models/application-user'

const Chat = namespace('Chat')
const Auth = namespace('Auth')

@Component({
  components: { Icon, ChatBox }
})
export default class ChatLink extends Vue {
  @Auth.State('user')
  private currentUser!: ApplicationUser;

  @Chat.Action
  private toggleChatBox: () => void;

  @Chat.Action
  private clearMessages: () => void;

  @Chat.State
  private chatActive;

  @Prop() toUser;

  private toggleChat (): void {
    this.toggleChatBox()
    if (!this.chatActive) {
      ChatService.closeConnection()
      this.clearMessages()
    }
  }
}
