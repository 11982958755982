// A function to send user data to GTM
/* eslint-disable dot-notation */
export function pushUserDataToGTM (eventName: string, userId: number): void {
  console.log(userId)
  if (window['dataLayer']) {
    window['dataLayer'].push({
      event: eventName,
      userId: userId
    })
  } else {
    console.warn('GTM dataLayer is not defined')
  }
}
/* eslint-enable dot-notation */
