

import { Component, Prop, Vue } from 'vue-property-decorator'
import AdminService from '@/services/AdminService'
import Icon from '@/components/Icon.vue'

@Component({
  components: { Icon }
})
export default class CategorySection extends Vue {
  @Prop() title: string;
  @Prop() allItems: any;
  @Prop() itemType: string;

  private display = 'none';
  private toggleIconName = 'expand';

  private submitItem: any = {
    name: '',
    description: '',
    active: true
  }

  private itemFields = [
    {
      key: 'text',
      label: 'nimetus',
      sortable: true
    },
    {
      key: 'description',
      label: 'kirjeldus',
      sortable: true
    },
    {
      key: 'active',
      label: 'aktiivne',
      sortable: true
    },
    {
      key: 'updatedAt',
      label: 'viimati uuendatud',
      sortable: true
    },
    {
      key: 'options',
      label: ''
    }
  ]

  deleteItem (item: any): void {
    AdminService.deleteItem(item.type, item.value).then(() => {
      item.active = false
    })
  }

  activateItem (item: any): void {
    const payload = {
      id: item.value,
      name: item.text,
      description: item.description,
      active: true
    }
    AdminService.updateItem(item.type, payload).then(() => {
      item.active = true
    })
  }

  createItem (): void {
    AdminService.createItem(this.itemType, this.submitItem).then(() => {
      window.location.reload()
    })
  }

  toggleMenu (): void {
    if (this.display === 'block') {
      this.display = 'none'
      this.toggleIconName = 'expand'
    } else {
      this.display = 'block'
      this.toggleIconName = 'collapse'
    }
  }
}
