
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Toy } from '@/models/toy'
import DateUtilities from '@/utilities/DateUtilities'
import BasicService from '@/services/BasicService'
import AdminService from '@/services/AdminService'

const AppInfo = namespace('AppInfo')

@Component
export default class ToyManager extends Vue {
  private toys: Toy[] = [];

  private toyStatuses = [
    { text: 'active', value: 1 },
    { text: 'inactive', value: 2 }
  ];

  private allUsers = [];

  private sortBy = 'updatedAt'
  private sortDesc = true

  private currentPage = 1;

  private itemFields = [
    {
      key: 'id',
      label: 'ID',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'name',
      label: 'nimetus'
    },
    {
      key: 'applicationUser',
      label: 'kasutaja',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'price',
      label: 'punktid',
      sortable: true
    },
    {
      key: 'location',
      label: 'asukoht',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'condition.name',
      label: 'seisukord',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'createdAt',
      label: 'lisatud',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'updatedAt',
      label: 'viimati muudetud',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'toyStatus.name',
      label: 'staatus',
      sortable: true
    },
    {
      key: 'options',
      label: ''
    }
  ];

  private detailFields = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'name',
      label: 'nimetus'
    },
    {
      key: 'applicationUser',
      label: 'kasutaja'
    },
    {
      key: 'price',
      label: 'punktid'
    },
    {
      key: 'location',
      label: 'asukoht'
    },
    {
      key: 'condition.name',
      label: 'seisukord'
    },
    {
      key: 'createdAt',
      label: 'lisatud'
    },
    {
      key: 'updatedAt',
      label: 'viimati muudetud'
    },
    {
      key: 'toyStatus.name',
      label: 'staatus'
    },
    {
      key: 'toyInAgeGroups',
      label: 'vanusegrupid'
    },
    {
      key: 'toyInCategories',
      label: 'kategooriad'
    },
    {
      key: 'condition',
      label: 'seisukord'
    },
    {
      key: 'description',
      label: 'kirjeldus'
    }
  ];

  mounted (): void {
    this.fetchAllUsers()
    this.fetchAllToys()
  }

  private fetchAllToys () {
    BasicService.getAllToys().then(
      (response) => {
        this.toys = response.data
      }
    )
  }

  private fetchAllUsers (): void {
    AdminService.fetchAllUsers().then(
      (response) => {
        response.data.forEach(row => {
          this.allUsers.push({
            text: row.firstName + ' ' + row.lastName,
            value: row.id
          })
        })
      })
  }

  private updateToy (toy) {
    AdminService.updateToyAdmin(toy).then(() => {
      window.location.reload()
    })
  }

  private getFormattedDate (timestamp) {
    return DateUtilities.getDateTimeStringFromString(timestamp)
  }

  private selectDisabled (toy): boolean {
    return [3, 4].includes(toy.toyStatusId)
  }

  private getDetailFields () {
    return this.detailFields
  }
}

