
import { Component, Vue } from 'vue-property-decorator'
import ToyGrid from '@/components/ToyGrid.vue'
import Icon from '@/components/Icon.vue'
import ChatBox from '@/components/messaging/ChatBox.vue'
import Sorter from '@/components/Sorter.vue'
import UserHomePanel from '@/components/pages/homePage/UserHomePanel.vue'
import CategoryTag from '@/components/CategoryTag.vue'
import FilterMenu from '@/components/pages/homePage/FilterMenu.vue'
import SelectedFilters from '@/components/pages/homePage/SelectedFilters.vue'
import { namespace } from 'vuex-class'
import { Toy } from '@/models/toy'
import { AgeGroup, Category, Condition, Location } from '@/models/store'
import { ToySearchRequest } from '@/models/data'

const AppInfo = namespace('AppInfo')

@Component({
  components: { SelectedFilters, FilterMenu, UserHomePanel, ToyGrid, Icon, ChatBox, Sorter, CategoryTag }
})
export default class Home extends Vue {
  @AppInfo.Action private fetchToys: (request: ToySearchRequest) => void;
  @AppInfo.Action private updateSearchTerm: (term: string) => void;
  @AppInfo.Action private rememberPage: (data: {type: string, pageNr: number}) => void;
  @AppInfo.Getter private isToysReady: boolean;
  @AppInfo.Getter private toys: Toy[];
  @AppInfo.Getter private currentPageMain: number;
  @AppInfo.State private filterAgeGroups: AgeGroup[];
  @AppInfo.State private filterCategories: Category[];
  @AppInfo.State private filterConditions: Condition[];
  @AppInfo.State private filterLocations: Location[];
  private searchTerm = '';
  private sideNavWidth = '500px';
  private secondaryMenuOpen = false;
  private pageSize = 24;

  mounted (): void {
    this.fetch(this.currentPageMain)
  }

  private fetch (page: number): void {
    const request: ToySearchRequest = {
      ageGroupIds: this.filterAgeGroups.map(ag => ag.value),
      categoryIds: this.filterCategories.map(cat => cat.value),
      conditionIds: this.filterConditions.map(con => con.value),
      locations: this.filterLocations.map(loc => loc.text),
      toyStatusIds: [1],
      pageNumber: page,
      pageSize: this.pageSize,
      searchTerm: this.searchTerm
    }
    this.fetchToys(request)
  }

  private storePageNumber (page: number): void {
    this.rememberPage({ type: 'main', pageNr: page })
  }

  private openSecondaryMenu (open: boolean): void {
    this.secondaryMenuOpen = open
    this.sideNavWidth = open ? this.sideNavWidth = '964px' : this.sideNavWidth = '500px'
  }

  closeSecondaryMenu (): void {
    this.sideNavWidth = '500px'
    this.secondaryMenuOpen = false
  }
}
