import { Cart, SwapInOrder, SwapOrder } from '@/models/swap'
import { Toy } from '@/models/toy'

class CartService {
  getCart (): Cart {
    return JSON.parse(localStorage.getItem('cart'))
  }

  emptyCart (): void {
    localStorage.removeItem('cart')
  }

  addToCart (toy: Toy, swap: SwapInOrder) {
    const existingCart = localStorage.getItem('cart')
    if (existingCart) {
      const cart: Cart = JSON.parse(existingCart)
      const swapOrder: SwapOrder = cart.orders[swap.fromApplicationUserId]
      if (swapOrder === undefined) {
        cart.orders[swap.fromApplicationUserId] = {
          total: toy.price,
          fromApplicationUserId: swap.fromApplicationUserId,
          fromApplicationUser: swap.toy.applicationUser,
          swaps: [swap],
          toApplicationUserId: swap.toApplicationUserId
        }
      } else {
        const existingSwap = swapOrder.swaps.find(swap => swap.toyId === toy.id)
        if (!existingSwap) {
          swapOrder.swaps.push(swap)
          swapOrder.total += swap.toy.price
          cart.orders[swap.fromApplicationUserId] = swapOrder
        }
      }
      localStorage.setItem('cart', JSON.stringify(cart))
    } else {
      this.createNewCart(toy, swap)
    }
  }

  removeFromCart (fromUserId: number, toyId): void {
    const existingCart = localStorage.getItem('cart')
    if (existingCart) {
      const cart: Cart = JSON.parse(existingCart)
      const swapOrder: SwapOrder = cart.orders[fromUserId]
      if (swapOrder && swapOrder.swaps) {
        const itemPrice = swapOrder.swaps.filter(swap => swap.toyId === toyId)[0].toy.price
        swapOrder.swaps = swapOrder.swaps.filter(swap => swap.toyId !== toyId)
        swapOrder.total -= itemPrice
        cart.orders[fromUserId] = swapOrder
        if (cart.orders[fromUserId].swaps.length === 0) {
          delete cart.orders[fromUserId]
        }
        localStorage.setItem('cart', JSON.stringify(cart))
      }
    }
  }

  removeOrderFromCart (fromUserId: number) {
    const existingCart = localStorage.getItem('cart')
    if (existingCart) {
      const cart: Cart = JSON.parse(existingCart)
      delete cart.orders[fromUserId]
      localStorage.setItem('cart', JSON.stringify(cart))
    }
  }

  getItemsCount (): number {
    let count = 0
    const existingCart = localStorage.getItem('cart')
    if (existingCart) {
      const cart: Cart = JSON.parse(existingCart)
      Object.entries(cart.orders).forEach(entry => {
        count += entry[1].swaps.length
      })
    }
    return count
  }

  private addToTotal (order: SwapOrder, itemPrice: number) {
    return order.total + itemPrice
  }

  private subtractFromTotal (order: SwapOrder, itemPrice: number) {
    return order.total - itemPrice
  }

  private createNewCart (toy: Toy, swap: SwapInOrder) {
    const newCart: Cart = {
      orders: {}
    }
    newCart.orders[toy.applicationUserId] = {
      total: toy.price,
      fromApplicationUserId: swap.fromApplicationUserId,
      fromApplicationUser: swap.toy.applicationUser,
      swaps: [swap],
      toApplicationUserId: swap.toApplicationUserId
    }
    localStorage.setItem('cart', JSON.stringify(newCart))
  }
}

export default new CartService()
