
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import PhotoUtilities from '@/utilities/PhotoUtilities'

@Component
export default class UserAvatar extends Vue {
  @Prop() user: {
    pictureReference: null,
    firstName?: '',
    lastName?: '',
  }

  @Prop() big;
  @Prop() medium;
  @Prop() small;
  @Prop() newMessagesCount;

  get profilePicture (): string {
    if (this.user.pictureReference !== null) {
      return PhotoUtilities.getProfilePictureUrl(this.user.pictureReference)
    }
    return '//ssl.gstatic.com/accounts/ui/avatar_2x.png'
  }
}
