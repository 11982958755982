
import { Component, Prop, Vue } from 'vue-property-decorator'
import UserService from '@/services/UserService'

@Component
export default class ToyPhotoForm extends Vue {
  @Prop() productPhotos: any[];
  @Prop() newToyForm: boolean;
  @Prop() disabled: boolean;

  deletePhoto (id: number): void {
    this.$emit('deleteSelected', id)
  }
}
