

import { Component, Prop, Vue } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import { namespace } from 'vuex-class'
import LoggedOutNavbar from '@/components/navbar/LoggedOutNavbar.vue'

const Auth = namespace('Auth')

@Component({
  components: { Icon, UserAvatar, LoggedOutNavbar }
})
export default class DesktopNavbar extends Vue {
  @Auth.State('user')
  private currentUser

  @Prop()
  chatMessages

  @Prop()
  notifications

  @Prop()
  logOut

  @Prop()
  showAdminBoard

  @Prop()
  cartCount

  @Prop()
  userInfo
}
