

import ChatBox from '@/components/messaging/ChatBox.vue'
import Icon from '@/components/Icon.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import UserService from '@/services/UserService'
import ChatService from '@/services/ChatService'
import PhotoUtilities from '@/utilities/PhotoUtilities'
import DateUtilities from '@/utilities/DateUtilities'

const Auth = namespace('Auth')
const Chat = namespace('Chat')

@Component({
  components: { ChatBox, Icon }
})
export default class UserHomePanel extends Vue {
  @Auth.State('user')
  private currentUser!: any;

  @Chat.State
  private chatActive;

  @Chat.State
  private chatMessages;

  @Chat.State
  private notifications;

  @Chat.Action
  private toggleChatBox: () => void;

  @Chat.Action
  private clearMessages: () => void;

  @Chat.Action
  private clearMessage: (userId: number) => void;

  @Chat.Action
  private removeNotificationFromStore: (notificationId: number) => void;

  get profilePicture (): string {
    return PhotoUtilities.getProfilePictureUrl(this.currentUser.profilePictureReference)
  }

  private toggleChat (userId: number): void {
    this.toggleChatBox()
    if (!this.chatActive) {
      ChatService.closeConnection().then(() => {
        this.clearMessages()
        this.clearMessage(userId)
      })
    }
  }

  private getLastMessageSentAtDate (createdAt) {
    return DateUtilities.getDateStringFromString(createdAt)
  }

  private getLastMessageSentAtTime (createdAt) {
    return DateUtilities.getTimeStringFromString(createdAt)
  }

  private getNotificationDate (date) {
    return DateUtilities.getDateStringFromString(date)
  }

  private getNotificationTime (date) {
    return DateUtilities.getTimeStringFromString(date)
  }

  private markAsRead (notificationId: number): void {
    UserService.markNotificationAsOpened(this.currentUser.id, notificationId).then(() => {
      this.removeNotificationFromStore(notificationId)
    })
  }
}
