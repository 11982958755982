export const SWAP_STATUS_ID = {
  pending: 1,
  completed: 2,
  cancelled: 3,
  seller_confirmed: 4
}

export const SWAP_STATUS_NAMES = {
  1: 'ootel',
  2: 'lõpetatud',
  3: 'tühistatud',
  4: 'saatja kinnitatud'
}

export const TOY_STATUS_ID = {
  active: 1,
  inactive: 2,
  pending: 3,
  swapped: 4
}

export const TOY_STATUS_NAMES = {
  1: 'aktiivne',
  2: 'mitteaktiivne',
  3: 'ootel',
  4: 'vahetatud'
}
