import { Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import { Message } from '@/models/chat'
import { Notification } from '@/models/notification'

@Module({ namespaced: true })
class Chat extends VuexModule {
  private groupedMessages: {
    fromUserId: number,
    toUserId: number,
    messages: Message[]
  }[] = []

  private chatMessages = [];
  private notifications: Notification[] = [];
  private chatActive = false;

  @Mutation
  public storeMessage (message: Message): void {
    if (message.createdAt !== null && message.createdAt !== undefined) {
      message.createdAt = new Date(message.createdAt).toUTCString()
    } else {
      message.createdAt = new Date().toUTCString()
    }
    let messageGroup = this.groupedMessages.find(group => {
      return (group.fromUserId === message.fromApplicationUserId && group.toUserId === message.toApplicationUserId)
    })
    if (messageGroup === null || messageGroup === undefined) {
      messageGroup = {
        fromUserId: message.fromApplicationUserId,
        toUserId: message.toApplicationUserId,
        messages: []
      }
      messageGroup.messages.push(message)
      this.groupedMessages.push(messageGroup)
    } else {
      messageGroup.messages.push(message)
    }
  }

  @Mutation
  public toggleChat (): void {
    this.chatActive = !this.chatActive
  }

  @Mutation storeMessages (chats): void {
    this.chatMessages = chats
  }

  @Mutation
  public clearMessagesFromStore (): void {
    this.groupedMessages = []
  }

  @Mutation
  public clearMessageFromStore (userId: number): void {
    this.chatMessages = this.chatMessages.filter(chat => {
      return chat.fromApplicationUserId !== userId
    })
  }

  @Mutation
  public storeNotifications (notifications: Notification[]): void {
    this.notifications = notifications
  }

  @Mutation
  public removeNotification (notificationId: number): void {
    this.notifications = this.notifications.filter(not => {
      return not.id !== notificationId
    })
  }

  @Action
  storeNewMessage (message: Message): void {
    this.context.commit('storeMessage', message)
  }

  @Action
  toggleChatBox (): void {
    this.context.commit('toggleChat')
  }

  @Action
  clearMessages (): void {
    this.context.commit('clearMessagesFromStore')
  }

  @Action
  storeNewMessages (messages): void {
    this.context.commit('storeMessages', messages)
  }

  @Action
  clearMessage (userId: number): void {
    this.context.commit('clearMessageFromStore', userId)
  }

  @Action
  storeNewNotifications (notifications: Notification[]): void {
    this.context.commit('storeNotifications', notifications)
  }

  @Action
  removeNotificationFromStore (notificationId: number): void {
    this.context.commit('removeNotification', notificationId)
  }
}

export default Chat
