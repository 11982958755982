import axios from 'axios'
import authHeader from '@/services/auth-header'
import ServiceUtility from '@/utilities/EnvUtilities'
import { SwapInOrder, SwapOrder, SwapOrderRequest, SwapRequest } from '@/models/swap'

const API_URL = ServiceUtility.getApiBaseUrl()

class OrderService {
  async getUserSwapOrders (userId: string) {
    return axios.get(API_URL + 'SwapOrders/GetOrders/' + userId, { headers: authHeader() })
  }

  async getAllSwapOrders () {
    return axios.get(API_URL + 'SwapOrders/GetAll', { headers: authHeader() })
  }

  async getSwapOrder (orderId, userId) {
    const body = {
      orderId: orderId,
      userId: userId
    }
    return axios.post(API_URL + 'SwapOrders/GetOrder', body, { headers: authHeader() })
  }

  async sendOrder (order: SwapOrder) {
    const body = this.mapSwapOrderRequest(order)
    return axios.post(API_URL + 'SwapOrders/CreateOrder', body, { headers: authHeader() })
  }

  async updateOrderStatus (orderId: number, statusId: number, userId: number) {
    const body = {
      swapOrderId: orderId, swapStatusId: statusId, userId: userId
    }
    return axios.post(API_URL + 'SwapOrders/UpdateOrderStatus', body, { headers: authHeader() })
  }

  private mapSwapOrderRequest (order: SwapOrder): SwapOrderRequest {
    const swapOrderRequest: SwapOrderRequest = {
      fromApplicationUserId: order.fromApplicationUserId,
      swapStatusId: 1,
      swaps: this.mapSwaps(order.swaps),
      toApplicationUserId: order.toApplicationUserId,
      total: order.total
    }
    return swapOrderRequest
  }

  private mapSwaps (swaps: SwapInOrder[]): SwapRequest[] {
    const swapRequests: SwapRequest[] = []
    swaps.forEach(swap => {
      swapRequests.push({
        fromApplicationUserId: swap.fromApplicationUserId,
        toApplicationUserId: swap.toApplicationUserId,
        swapStatusId: 1,
        toyId: swap.toyId
      })
    })
    return swapRequests
  }
}

export default new OrderService()
