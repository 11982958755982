
import { Component, Vue } from 'vue-property-decorator'
import PrivacyPolicy from '@/components/pages/PrivacyPolicy.vue'
import TermsConditions from '@/components/pages/TermsConditions.vue'

@Component({
  components: { PrivacyPolicy, TermsConditions }
})
export default class Legal extends Vue {
  private showPrivacyPolicy = true;

  mounted (): void {
    if (this.$route.params.doc === 'privacypolicy') {
      this.showPrivacyPolicy = true
    } else if (this.$route.params.doc === 'termsandconditions') {
      this.showPrivacyPolicy = false
    } else {
      this.$router.push('/legal/privacypolicy')
    }
  }

  private showPrivacy () {
    this.showPrivacyPolicy = true
  }

  private showTerms () {
    this.showPrivacyPolicy = false
  }
}
