import { render, staticRenderFns } from "./PublicProfile.vue?vue&type=template&id=390cf3e3&scoped=true"
import script from "./PublicProfile.vue?vue&type=script&lang=ts"
export * from "./PublicProfile.vue?vue&type=script&lang=ts"
import style0 from "./PublicProfile.vue?vue&type=style&index=0&id=390cf3e3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "390cf3e3",
  null
  
)

export default component.exports