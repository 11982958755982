
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import PhotoUtilities from '@/utilities/PhotoUtilities'
import et from 'vee-validate/dist/locale/et'
import { ERROR_CODE } from '@/models/error-map'
import BigOrangeButton from '@/components/BigOrangeButton.vue'
import { errors } from 'jose'
import Icon from '@/components/Icon.vue'

const Auth = namespace('Auth')

@Component({
  computed: {
    errors () {
      return errors
    }
  },
  components: { Icon, BigOrangeButton }
})
export default class Profile extends Vue {
  @Auth.State('user')
  private currentUser!: any;

  @Auth.Action
  private updateProfile!: (data: any) => Promise<any>;

  private updatedUser: any = {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    password: null,
    facebook: null,
    emailNotificationsEnabled: null,
    referralCode: null
  };

  private message = '';
  private submitted = false;
  private disabled = true;
  private successful = false;
  private selectedProfilePicture: any = null;
  private changed = false;
  private showSpinner = false;

  mounted (): void {
    this.updatedUser.id = this.currentUser.id
    this.updatedUser.email = this.currentUser.email
    this.updatedUser.firstName = this.currentUser.firstName
    this.updatedUser.lastName = this.currentUser.lastName
    this.updatedUser.facebook = this.currentUser.facebook ? this.currentUser.facebook.replace('https://www.facebook.com/', '') : ''
    this.updatedUser.referralCode = this.currentUser.referralCode
    this.updatedUser.emailNotificationsEnabled = this.currentUser.emailNotificationsEnabled
    this.$validator.localize('et', et)
    this.scaleImage()
  }

  private touched () {
    this.changed = true
    this.$validator.validateAll().then((isValid) => {
      if (isValid) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    })
  }

  copyShareLink (): void {
    navigator.clipboard.writeText('https://www.leluelu.ee?ref=' + this.updatedUser.referralCode)
    this.$bvToast.show('notification-toast')
  }

  handleUpdate (): void {
    this.message = ''
    this.submitted = true
    this.disabled = true
    this.showSpinner = true

    if (this.changed) {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.updatedUser.profilePicture = this.selectedProfilePicture
          this.updateProfile(this.updatedUser).then(
            () => {
              window.location.reload()
            },
            (error) => {
              this.message = ERROR_CODE[error]
              this.successful = false
              this.updatedUser.password = null
              this.showSpinner = false
            }
          )
        }
      })
    }
  }

  private async onFileSelected (event: any) {
    this.changed = true
    const image = event.target.files[0]
    const inputPreview = new Image()
    inputPreview.src = URL.createObjectURL(image)

    const imageElement = document.getElementById('image-preview')
    imageElement.setAttribute('src', inputPreview.src)

    await PhotoUtilities.compressImage(inputPreview, 300, 300).then((result) => {
      this.scaleImage()
      this.selectedProfilePicture = result
    }).then(() => {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.disabled = false
        } else {
          this.disabled = true
        }
      })
    })
  }

  scaleImage () {
    const imageElement = document.getElementById('image-preview')
    if (imageElement.clientWidth > imageElement.clientHeight) {
      imageElement.style.height = '250px'
      imageElement.style.width = 'auto'
    } else {
      imageElement.style.width = '250px'
      imageElement.style.height = 'auto'
    }
  }

  get profilePicture (): string {
    return PhotoUtilities.getProfilePictureUrl(this.currentUser.profilePictureReference)
  }
}
