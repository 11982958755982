import EnvUtilities from '@/utilities/EnvUtilities'

class PhotoUtilities {
  getProductPhotoUrl (photoReference: string): string {
    if (photoReference && photoReference !== undefined && photoReference !== '0') {
      return `https://${EnvUtilities.getProductPhotoBucket()}.s3.eu-west-1.amazonaws.com/${photoReference}.jpeg`
    }
  }

  getProfilePictureUrl (profilePictureReference: string): string {
    if (profilePictureReference && profilePictureReference !== undefined && profilePictureReference !== null && profilePictureReference !== '0') {
      return `https://${EnvUtilities.getProfilePhotoBucket()}.s3.eu-west-1.amazonaws.com/${profilePictureReference}.jpeg`
    }
    return '//ssl.gstatic.com/accounts/ui/avatar_2x.png'
  }

  // instructions from:
  // https://medium.com/swlh/boost-server-performance-with-client-side-image-compression-cdefba1c1c0d
  async compressImage (image: HTMLImageElement, maxWidth: number, maxHeight: number): Promise<Blob> {
    // @ts-ignore
    const { height, width } = await this.getImageDimensions(image)

    const scale = Math.min((maxWidth / width), (maxHeight / height))

    return new Promise<Blob>((resolve) => {
      const canvas = document.createElement('canvas')
      canvas.width = scale * width
      canvas.height = scale * height

      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

      ctx.canvas.toBlob((blob) => {
        resolve(blob)
      }, 'image/jpeg', 1)
    })
  }

  private async getImageDimensions (image) {
    return new Promise((resolve) => {
      image.onload = function () {
        const width = this.width
        const height = this.height
        resolve({ height, width })
      }
    })
  }
}

export default new PhotoUtilities()
