

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ChatService from '@/services/ChatService'
import { namespace } from 'vuex-class'
import { Message } from '@/models/chat'
import UserService from '@/services/UserService'
import UserAvatar from '@/components/UserAvatar.vue'
import DateUtilities from '@/utilities/DateUtilities'
import Icon from '@/components/Icon.vue'
import { Route } from 'vue-router'

const Auth = namespace('Auth')
const Chat = namespace('Chat')

@Component({
  components: { Icon, UserAvatar }
})
export default class ChatBox extends Vue {
  @Auth.State('user')
  private currentUser!: any;

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange (newVal: Route) {
    ChatService.closeConnection()
  }

  @Prop() toUser;
  @Prop() fromUser;

  @Chat.Action
  private storeNewMessage: (message: Message) => void;

  @Chat.Action
  private toggleChatBox: () => void;

  @Chat.Action
  private clearMessages: () => void;

  @Chat.Action
  private clearMessage: (userId: number) => void;

  @Chat.State('groupedMessages')
  private allMessages;

  private correspondence = [];

  private message = {
    title: undefined,
    content: undefined
  };

  created (): void {
    if (this.toUser.id === undefined || this.toUser.id === null || this.toUser.id === 0) {
      this.toggleChatBox()
      return
    }
    this.correspondence = []
    this.clearMessages()
    UserService.getCorrespondence(this.fromUser.id, this.toUser.id)
      .then((response) => {
        const data = response.data
        for (const index in data) {
          const message = data[index]
          this.storeNewMessage(message)
        }
      }).then(() => {
        ChatService.startConnection().then(ChatService.connectToChat)
        this.setupCallbacks()
        this.updateCorrespondence()
      }).then(() => {
        UserService.markMessagesAsOpened(this.fromUser.id, this.toUser.id)
      }).then(() => {
        this.scrollToBottom()
      })
  }

  private setupCallbacks (): void {
    ChatService.addCallbackToConnection('MessageReceived', (message) => {
      this.handleCallback(message).then(() => {
        this.scrollToBottom()
      })
    })
  }

  private async handleCallback (message) {
    const parsedMessage = JSON.parse(message)
    this.storeNewMessage(parsedMessage)
    await UserService.markMessagesAsOpened(parsedMessage.fromApplicationUserId, parsedMessage.toApplicationUserId)
    this.updateCorrespondence()
  }

  private async sendMessage (event) {
    event.preventDefault()
    const message: Message = {
      title: this.message.title,
      fromApplicationUserId: this.fromUser.id,
      toApplicationUserId: this.toUser.id,
      fromApplicationUserFirstName: this.fromUser.firstName,
      toApplicationUserFirstName: this.toUser.firstName,
      fromApplicationUserProfilePictureReference: this.currentUser.profilePictureReference,
      content: this.message.content,
      messageStatus: 'new'
    }

    this.message = {
      title: undefined,
      content: undefined
    }

    if (message.content !== null && message.content !== undefined) {
      await ChatService.sendNewMessage(message).then(() => {
        this.storeNewMessage(message)
        this.updateCorrespondence()
      }).then(() => {
        this.scrollToBottom()
      })
    }
  }

  private updateCorrespondence (): void {
    this.correspondence = []
    const messagesToCurrentUser: any = this.allMessages.find(messageGroup => this.filterCorrespondence(messageGroup, this.fromUser.id, this.toUser.id))
    const messagesFromCurrentUser: any = this.allMessages.find(messageGroup => this.filterCorrespondence(messageGroup, this.toUser.id, this.fromUser.id))
    let combinedMessages = []
    if (messagesToCurrentUser !== undefined && messagesFromCurrentUser !== undefined) {
      combinedMessages = messagesToCurrentUser.messages.concat(messagesFromCurrentUser.messages)
    } else if (messagesFromCurrentUser !== undefined && messagesToCurrentUser === undefined) {
      combinedMessages = messagesFromCurrentUser.messages
    } else if (messagesFromCurrentUser === undefined && messagesToCurrentUser !== undefined) {
      combinedMessages = messagesToCurrentUser.messages
    }
    this.correspondence = combinedMessages.sort((message1, message2) => DateUtilities.compareDates(message1.createdAt, message2.createdAt))
  }

  private filterCorrespondence (messageGroup, fromUserId, toUserId) {
    return (messageGroup.fromUserId === fromUserId && messageGroup.toUserId === toUserId)
  }

  private scrollToBottom (): void {
    const messageContainer = document.getElementById('messages-container')
    if (messageContainer !== null) {
      messageContainer.scrollTop = messageContainer.scrollHeight + messageContainer.scrollHeight
    }
  }

  private toggleChat (userId: number): void {
    this.toggleChatBox()
    this.clearMessages()
    this.clearMessage(userId)
    this.correspondence = []
    ChatService.closeConnection()
  }

  private getDateTime (message: Message): string {
    return DateUtilities.getDateTimeStringFromDate(new Date(message.createdAt))
  }
}

