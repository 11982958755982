
import UserAvatar from '@/components/UserAvatar.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ChatData } from '@/models/chat'
import UserService from '@/services/UserService'
import DateUtilities from '@/utilities/DateUtilities'

@Component({
  components: { UserAvatar }
})
export default class ContactsNav extends Vue {
  @Prop() currentUser;

  private chatData: ChatData[] = []

  private isBusy = true;

  created (): void {
    this.getNewMessages()
  }

  private getNewMessages (): void {
    const notifications = { groupedMessages: [] }
    UserService.getNewMessages(this.currentUser.id).then(
      (response) => {
        Object.keys(response.data).forEach(key => {
          const messageGroup = {
            fromUserId: Number(key),
            messages: response.data[key].length
          }
          notifications.groupedMessages.push(messageGroup)
        })
      }
    ).then(() => {
      UserService.getAllChatsNew(this.currentUser.id).then((response) => {
        const unorderedChatData = []
        for (const chat in response.data) {
          const data = response.data[chat]
          if (data.fromApplicationUserId !== this.currentUser.id) {
            unorderedChatData.push({
              id: `${data.fromApplicationUserId}`,
              data: data,
              newMessages: data.newMessageCount,
              updatedAt: data.updatedAt != null ? data.updatedAt : data.createdAt
            })
          }
        }
        this.chatData = unorderedChatData.sort((contact1, contact2) => DateUtilities.compareDates(contact2.updatedAt, contact1.updatedAt))
        // @ts-ignore
        this.isBusy = false
      })
    })
  }
}
